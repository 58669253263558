import { useEffect, useState } from 'react';

import ExportedImage from 'next-image-export-optimizer';
import Link from 'next/link';
import { useRouter } from 'next/router';

import pathsList from '@/data/paths_list.json';
import error404 from '@/public/images/lp/404/404.svg';

// Function to find the nearest existing parent URL
const findNearestParentUrl = (path) => {
  // Remove trailing slash if present and ensure path starts with /
  const normalizedPath = (path.endsWith('/') ? path.slice(0, -1) : path) || '/';
  // Split the path into segments
  const segments = normalizedPath.split('/').filter(Boolean);
  // Start with all segments and try removing one at a time from the end
  while (segments.length > 0) {
    const testPath = '/' + segments.join('/') + '/';
    // Convert paths_list entries to relative paths for comparison
    const relativePaths = pathsList.map((url) => {
      try {
        return new URL(url).pathname;
      } catch {
        return url;
      }
    });
    // Check if this path exists in our valid routes
    if (relativePaths.includes(testPath)) {
      return testPath;
    }

    // Try the next parent path
    segments.pop();
  }

  // If no parent found, return the homepage
  return '/en/';
};

export default function Custom404() {
  const router = useRouter();
  const [parentUrl, setParentUrl] = useState('/en/');

  useEffect(() => {
    // Find the nearest parent URL when the component mounts
    const nearestParent = findNearestParentUrl(router.asPath);
    setParentUrl(nearestParent);
  }, [router.asPath]);

  return (
    <>
      <div className='absolute h-full w-full overflow-hidden'>
        <div className='blog-ellipse-1 absolute'></div>
      </div>
      <main className='container relative mx-auto overflow-hidden font-sans text-white md:grid md:grid-cols-24 md:gap-0'>
        <div className='hidden lg:col-span-2 lg:block'></div>
        <div className='relative px-4 xs:px-8 md:col-span-24 lg:col-span-20 lg:px-0'>
          <section className='relative flex min-h-screen items-center pb-24 pt-32 md:pt-52'>
            <div className='flex w-full flex-col items-center'>
              <div className='w-[250px] lg:w-[376px]'>
                <svg
                  width='1032'
                  id='orbit-bg'
                  className='absolute left-1/2 top-1/2 z-[-10] -translate-x-1/2 -translate-y-1/2 opacity-10 3xl:top-[60%] 3xl:scale-[130%]'
                  height='836'
                  viewBox='0 0 1032 836'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g style={{ mixBlendMode: 'overlay' }}>
                    <path
                      d='M149.313 217.736C241.612 162.465 494.773 106.136 769.028 322.979C1111.85 594.034 375.426 559.669 134.417 261.716'
                      stroke='white'
                    />
                    <path
                      d='M370.281 695.108C315.01 602.809 258.681 349.648 475.524 75.392C746.579 -267.428 712.214 468.994 414.261 710.004'
                      stroke='white'
                    />
                    <path
                      d='M37.9433 509.834C86.8822 371.693 303.809 92.8238 780.006 82.4763C1375.25 69.5419 572.171 672.613 60.4782 568.941'
                      stroke='white'
                    />
                  </g>
                </svg>
                <ExportedImage
                  placeholder='empty'
                  src={error404}
                  alt='404 page not found'
                  className='h-auto'
                />
                <h1 className='pt-2 text-5xl font-bold uppercase leading-[98.8%] text-white lg:text-[80px] lg:leading-[79px]'>
                  Page not found
                </h1>
                <p className='pb-6 pt-2 text-xl font-normal'>
                  Sorry, we couldn&apos;t find the page you&apos;re looking for.
                </p>
                <Link
                  href={parentUrl}
                  className='back-to-homepage flex h-[40px] w-[230px] items-center justify-center rounded-[9px] border-2 border-solid text-center text-base font-bold text-white hover:bg-indigo-500 hover:text-white md:text-base lg:h-[50px] lg:w-[365px]'
                >
                  <p>Go back to parent page</p>
                </Link>
              </div>
            </div>
          </section>
        </div>
        <div className='hidden lg:col-span-2 lg:block'></div>
      </main>
    </>
  );
}
